import React, {useCallback, useEffect, useRef} from "react";
import styled, {ThemeProvider} from "styled-components";
import withLayout from "../layout";
import Header from "../components/Header";
import Fade from "react-reveal/Fade.js";
import {Heading, List, Paragraph} from "../components/UI/Typography.js";
import Form from "../components/Form";
import dark from "../theme/dark.js";
import {FormattedMessage, injectIntl} from "react-intl";
import SEO from "../components/SEO";
import Drop from "../images/drop.svg";
import {BulletIcon} from "../images/icons";
import DropBlurBg from "../images/contact_us/DropBlurBg.svg"
import DropBg from "../components/UI/DropBg";
import * as Yup from "yup";
import {dropCursorMirror} from "../animations/homePage";
import {theme, formEndpoints} from "../utilities/constants"

const Wrapper = styled.div`
    background-color: #1D0F41;
    .head {
        margin-bottom: 88px;
    }
    .drop {
        position: absolute;
        top: 0;
        right: 16px;
        transform: rotate(180deg);
    }
    .content {
        padding-top: 200px;
        padding-bottom: 116px;
        .label {
            font-family: 'proxima-nova';
            color: #B84FF8;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            margin-bottom: 8px;
        }
        ul {
            margin: 0;
            padding: 0;
            p, a {
                color: #FFF;
                text-decoration: none;
                margin: 0;
                font-size: 14px;
                line-height: 29px;
                letter-spacing: 1.13px;
            }
        }
    }
}`;

const ContactUsPage = ({intl}) => {
    const keywords = intl.formatMessage({id: 'contactus.meta_keywords'}).split(',') || [];

    const formFields = [
        {
            name: 'first_name',
            label: 'contactus.form_name',
            required: true,
            type: 'input-text',
        },
        {
            name: 'last_name',
            label: 'contactus.form_lastname',
            required: true,
            type: 'input-text',
        },
        {
            name: 'phone',
            label: 'contactus.form_phone',
            required: true,
            type: 'input-text',
        },
        {
            name: 'email',
            label: 'contactus.form_email',
            required: true,
            type: 'input-email',
        },
        {
            name: 'business_name',
            label: 'contactus.form_business_name',
            type: 'input-text',
        },
        {
            name: 'business_type',
            label: 'contactus.form_business_type',
            type: 'input-text',
        },
        {
            name: 'website',
            label: 'contactus.form_website',
            type: 'input-text',
        },
        {
            name: 'budget',
            label: 'contactus.form_budget',
            placeholder: 'contactus.form_budget_placeholder',
            type: 'select',
            options: [
                {label: "5K - 15K", value: 5000},
                {label: "15K - 45K", value: 15000},
                {label: "45K - 100K+", value: 45000},
            ]
        },
        {
            name: 'message',
            label: 'contactus.form_message',
            required: true,
            type: 'textarea',
        },
        {
            name: 'privacy',
            label: 'jobs.form_personal_data',
            required: true,
            type: 'checkbox',
        }
    ];

    const validationSchema = Yup.object({
        first_name: Yup.string().required(intl.formatMessage({id: 'contactus.error_required'})),
        last_name: Yup.string().required(intl.formatMessage({id: 'contactus.error_required'})),
        email: Yup.string().email(intl.formatMessage({id: 'contactus.error_bad_email'})).required(intl.formatMessage({id: 'contactus.error_required'})),
        phone: Yup.string().required(intl.formatMessage({id: 'contactus.error_required'})),
        business_name: Yup.string(),
        business_type: Yup.string(),
        website: Yup.string().url(intl.formatMessage({id: 'contactus.error_bad_url'})),
        budget: Yup.object().nullable(),
        message: Yup.string().required(intl.formatMessage({id: 'contactus.error_required'})),
        privacy: Yup.boolean().required(intl.formatMessage({id: 'contactus.error_required'})).oneOf([true], intl.formatMessage({id: 'contactus.error_required'}))
    });

    const formDataToSend = useCallback((values) => ({
        ...values,
        from: location.href,
        budget: values?.budget?.value || null
    }), [])

    const dropRef = useRef(null);

    useEffect(() => {
        if(dropRef.current) {
            window?.addEventListener('mousemove', playDropAnimation);

            return () => {
                window?.removeEventListener('mousemove', playDropAnimation);
            }
        }
    }, []);

    const playDropAnimation = (e) => {
        const dropAnimation = dropCursorMirror(e, dropRef);
        dropAnimation.play();
    };

    return <>
        <SEO title={intl.formatMessage({id: 'contactus.meta_title'})}
             description={intl.formatMessage({id: 'contactus.meta_description'})} keywords={keywords}
             lang={intl.locale}/>
        <ThemeProvider theme={dark} colors={dark ? dark.colors : null}>
            <Wrapper>
                <Header siteTitle={"kromin"} hideLangs={false}/>
                <div className="container content">
                    <DropBg src={DropBlurBg} top={"0%"} right={"0%"}/>
                    <div className="row head">
                        <div className="col-12 position-relative">
                            <Fade bottom delay={500}>
                                <Heading level={"1"} topDot thinChild={1}>
                                    <FormattedMessage id={"contactus.title_thin"}/><br/>
                                    <FormattedMessage id={"contactus.title_strong"}/>
                                </Heading>
                            </Fade>
                            <Fade bottom delay={800}>
                                <Paragraph customColor={"white"}>
                                    <FormattedMessage id={"contactus.subtitle"}/>
                                </Paragraph>
                            </Fade>
                            <Fade bottom delay={500}>
                                <div className="d-sm-block d-none drop">
                                    <img src={Drop} alt='drop' ref={dropRef}/>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <Fade bottom delay={950}>
                                <Form formTheme={theme.DARK} formFields={formFields} ctaText="contactus.send"
                                      successMessage="contactus.form_submit_success_text" successTitle="contactus.form_submit_success"
                                      isContactUs={true} validationSchema={validationSchema} endpoint={formEndpoints.CONTACT_US}
                                      formDataToSend={formDataToSend}
                                />
                            </Fade>
                        </div>
                        <div
                            className="col offset-lg-1 offset-0 mt-lg-0"
                            style={{marginTop: 116}}
                        >
                            <Fade bottom delay={950}>
                                <div className="row">
                                    <div className="col-12 mb-sm-4 mb-0 contactus-section-list">
                                        <label className="label"><FormattedMessage id={"contactus.operational_sites"}/>:</label>
                                        <ul>
                                            <List>
                                                <BulletIcon className="mr-2" small={true}/>
                                                <p><FormattedMessage id="contactus.contacts_info.street_1"/></p>
                                            </List>
                                            <List>
                                                <BulletIcon className="mr-2" small={true}/>
                                                <p><FormattedMessage id="contactus.contacts_info.street_2"/></p>
                                            </List>
                                        </ul>
                                    </div>
                                    <div className="col-12 contactus-section">
                                        <label className="label"><FormattedMessage id={"contactus.contacts"}/>:</label>
                                        <ul>
                                            <List>
                                                <p><a href="mailto:info@kromin.it "><FormattedMessage
                                                    id="contactus.contacts_info.email"/></a></p>
                                            </List>
                                            <List>
                                                <p><a href="tel:+39 0775 15 24 926"><FormattedMessage
                                                    id="contactus.contacts_info.telephone"/></a></p>
                                            </List>
                                        </ul>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </ThemeProvider>
    </>
}

const customProps = {
    localeKey: "contactus", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(ContactUsPage));
